import React, { Fragment, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import {
    ThemedButton,
    TextDefault,
    Heading2,
    FlexDiv,
    TrainingCompletedAnimation,
    BigModal,
} from '@awarego/awarego-components';
import ThemedLessonsCompleted from '../../components/themed/ThemedLessonsCompleted';
import { useTranslation } from 'react-i18next';
import { formatDate, isInvalidUserName } from '../../utils/helpers';
import DownloadCertificateForm from '../DownloadCertificateForm';
import { useStores } from '../../hooks/use-stores';

export default observer(({ training, isDemo, isScorm, store }) => {
    const { t } = useTranslation();

    const { authStore, brandingStore, demoStore } = useStores();
    const { creatingCertificate } = store;

    const [certificateModal, setCertificateModal] = useState(false);

    const currentUser = useMemo(() => {
        if (isScorm) return null;
        if (isDemo) {
            return demoStore.demoUser;
        }
        return authStore.currentUser;
    }, [isDemo, demoStore, authStore, isScorm]);

    const shouldShowCertificateModal = useMemo(() => {
        if (isScorm) return brandingStore.scormCertificate;
        if (isDemo && currentUser.name) return false;
        return isInvalidUserName(currentUser.name);
    }, [isDemo, currentUser, isScorm]);

    const getCertificate = async () => {
        if (shouldShowCertificateModal) {
            setCertificateModal(true);
        } else {
            await downloadCertificate(
                currentUser.name || demoStore.demoUser.name,
                true
            );
        }
    };

    const closeCertificateModal = async () => {
        setCertificateModal(false);
    };

    const downloadCertificate = async (name) => {
        await store.downloadCertificate(training.id, name);
    };

    return (
        <FlexDiv column my={32} alignCenter fullWidth>
            {training.nextSubjectDate ? (
                <FlexDiv column alignCenter justifyCenter gap={24}>
                    <ThemedLessonsCompleted />
                    <Heading2 center>
                        {t('All Assigned lessons')}{' '}
                        <Heading2 themed>{t('completed')}</Heading2>
                    </Heading2>
                    <TextDefault lighter center maxWidth={470}>
                        {t('Great job! Next video will be available on')}{' '}
                        {formatDate(training.nextSubjectDate)}
                    </TextDefault>
                </FlexDiv>
            ) : (
                <Fragment>
                    <FlexDiv width={280} height={280}>
                        <TrainingCompletedAnimation
                            primaryColor={brandingStore.theme.primary}
                        />
                    </FlexDiv>
                    <Heading2>
                        {t('Training')}{' '}
                        <Heading2 themed>
                            {t('completed successfully')}
                        </Heading2>
                    </Heading2>
                    <br />
                    {!isDemo && (
                        <TextDefault lighter center maxWidth={470} mb={32}>
                            {t(
                                "Great job! We'll notify you by email when a new training gets assigned to you."
                            )}
                        </TextDefault>
                    )}
                    {(!isScorm || brandingStore.scormCertificate) && (
                        <>
                            <ThemedButton
                                mode="primary"
                                onClick={getCertificate}
                                disabled={creatingCertificate}
                                text={t('Download Certificate')}
                            />
                            {creatingCertificate && (
                                <TextDefault
                                    lighter
                                    center
                                    maxWidth={470}
                                    mt={16}
                                >
                                    {t(
                                        'Your certificate should download in a few moments'
                                    )}
                                </TextDefault>
                            )}

                            <BigModal
                                isOpen={certificateModal}
                                width={400}
                                onRequestClose={closeCertificateModal}
                                heading={t('Get your Certificate!')}
                                modalContent={
                                    <DownloadCertificateForm
                                        onDownload={downloadCertificate}
                                        isDemo={isDemo}
                                        isScorm={isScorm}
                                    />
                                }
                            />
                        </>
                    )}
                </Fragment>
            )}
        </FlexDiv>
    );
});
