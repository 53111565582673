import RestService from './RestService';
import Events from './EventsService';

export default class Cmi5 extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/cmi5`);
        } else throw new Error('company is required');
    }

    package(uniqueId, params) {
        return this.get(`${this.baseUrl}/package/${uniqueId}`, params);
    }

    myAssessment(id, params) {
        return this.get(`${this.baseUrl}/my-assessment/${id}`, params);
    }

    startAssessment(id, params) {
        return this.post(`${this.baseUrl}/my-assessment/${id}/run`, params);
    }

    results(id, params) {
        return this.get(`${this.baseUrl}/my-assessment/${id}/results`, params);
    }

    answers(id, params) {
        return this.get(`${this.baseUrl}/my-assessment/${id}/answers`, params);
    }

    async getPersonalizedQuestion(questionId, assessmentId, params) {
        return this.get(
            `${this.baseUrl}/my-assessment/${assessmentId}/question/${questionId}`,
            params
        );
    }

    questionActions(assessmentId, questionId, actions, params) {
        return this.post(
            `${this.baseUrl}/my-assessment/${assessmentId}/question/${questionId}/action`,
            { actions, ...params }
        );
    }

    myTraining(id, params) {
        return this.get(`${this.baseUrl}/training/${id}`, params);
    }

    eventsService() {
        return new Events(this);
    }

    getVideoToken(id, params) {
        return this.post(`${this.baseUrl}/training/${id}/videoToken`, params);
    }

    downloadCertificate(trainingId, params, options) {
        return this.get(
            `${this.baseUrl}/download/certificate/${trainingId}`,
            params,
            options
        );
    }
}
