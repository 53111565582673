import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import Subject from '../../components/subject/subject';
import { useStores } from '../../hooks/use-stores';
import { subjectProgress } from '../../utils/helpers';
import io from 'socket.io-client';

const CONSTANTS = require('../../utils/constants');

function useSubjectData(subject) {
    if (subject && subject.status) {
        if (subject.status.VIDEO_100 === 1) {
            return 100;
        }
        if (subject.status.VIDEO_95 === 1) {
            return 95;
        }
        if (subject.status.VIDEO_75 === 1) {
            return 75;
        }
        if (subject.status.VIDEO_50 === 1) {
            return 50;
        }
        if (subject.status.VIDEO_25 === 1) {
            return 25;
        }
    }
    return 0;
}

export default observer(
    ({ training, subject, language, store, videoTokenStore }) => {
        const { trainingStore, authStore } = useStores();
        useEffect(() => {
            logEvent(subject, CONSTANTS.EVENTS.SUBJECT_OPEN);
            console.log('open socket');

            const socket = io.connect(process.env.REACT_APP_API_URL, {
                query: store.rxd
                    ? `token=${Buffer.from(
                          JSON.stringify({
                              package: training.id,
                              ...store.cmiRequestParamForSocket,
                          })
                      ).toString('base64')}`
                    : `token=${authStore.token}`,
                transports: ['websocket'],
            });

            socket.on('unauthorized', (error) => {
                if (
                    error.data.type === 'UnauthorizedError' ||
                    error.data.code === 'invalid_token'
                ) {
                    console.log('unathorized');
                }
            });

            socket.on('connect', function () {
                console.log('connected');
            });

            socket.on('training_complete', function (data) {
                logEvent(data.subjectId, CONSTANTS.EVENTS.VIDEO_100, null, {
                    send: false,
                });
                logEvent(
                    data.subjectId,
                    CONSTANTS.EVENTS.ANSWARE_CORRECT,
                    null,
                    {
                        send: false,
                    }
                );
                console.log('training_complete', data);
            });

            socket.on('state_saved', function (data) {
                console.log('state_saved', data);
            });
            socket.on('disconnect', function () {
                console.log('disconnect');
            });

            return () => {
                socket.disconnect();
            };
        }, [training, subject]);

        const currentSubjectVideoProgress = useSubjectData(subject);

        React.useEffect(() => {
            store.setCurrentSubjectAnswers(subject);
        }, [subject, language, store]);

        const logEvent = useCallback(
            (pSubject, event_id, context, options) => {
                store.onLogEvent(
                    training.id,
                    subject,
                    event_id,
                    context,
                    options
                );
                trainingStore.onLogEvent(
                    training.id,
                    subject,
                    event_id,
                    context,
                    options
                );
            },
            [training, subject, store, trainingStore]
        );

        const step2Available = subjectProgress(subject) >= 95;
        const questionsAvailable = subject && subject.question != null;
        if (!questionsAvailable && step2Available && subject.type === 1) {
            logEvent(subject, CONSTANTS.EVENTS.ANSWARE_CORRECT);
        }

        return (
            <Subject
                subject={subject}
                training={training}
                shuffledAnswers={store.answers.map((x) => x)}
                logEvent={logEvent}
                questionsAvailable={questionsAvailable}
                step2Available={step2Available}
                currentSubjectVideoProgress={currentSubjectVideoProgress}
                videoTokenStore={videoTokenStore}
            />
        );
    }
);
